import $ from 'jquery';

$(() => {
    function toggle_cv_personal_form() {
        $('div#info_cv_personal_form').collapse('toggle');
    }
    function toggle_hotlist() {
        $('div#info_hotlist').collapse('toggle');
    }

    $('p.teaser_cv_personal_form').on('click', toggle_cv_personal_form);
    $('p.teaser_hotlist').on('click', toggle_hotlist);
});
